:root {
  --bg-color: #f8f8f9;
  --text-color: #333;
  --primary-color: #5a67d8; /* A general modern primary color */
  --link-color: #4fd1c5; /* A modern complementary color for links */
  --font-base: "Segoe UI", "Helvetica Neue", "Inter", "Aptos", "Arial", -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", sans-serif;

  --border-radius: 4px;
  --box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  
  --max-container-width: 500px; /* Set maximum width */
}

body {
  font-family: var(--font-base);
  background-color: var(--bg-color);
  color: var(--text-color);
  margin: 0;
}
