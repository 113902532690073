.App {
  text-align: center;
  padding-bottom: 2rem; /* Give some space at the bottom */
}

.App-logo {
  height: 80px;
  pointer-events: none;
  animation: App-logo-spin infinite 20s linear;
}

@media (prefers-reduced-motion: reduce) {
  .App-logo {
    animation: none;
  }
}

@media (max-width: 600px) {
  .App-logo {
    height: 60px;
  }
}

.App-header {
  padding: 2rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: var(--link-color);
  text-decoration: none;
  padding: 8px 0;
  transition: color 200ms ease-in-out;
}

.App-link:hover,
.App-link:focus {
  color: var(--primary-color);
}

.app-container {
  max-width: var(--max-container-width);
  margin: 0 auto; /* Centering the container */
  padding: 1rem;
  border-radius: var(--border-radius);
  background-color: #fff;
  box-shadow: var(--box-shadow);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Adjust animated items for subtlety */
.wipe-reveal-right {
  overflow: hidden;
  position: relative;
}
.wipe-reveal-right::after {
  transform: translateX(-100%);
}
.wipe-reveal-right.animate::after {
  transform: translateX(0);
}

.fade-disappear-up {
  opacity: 1;
  transition: opacity 500ms ease-in, transform 500ms ease-in;
}

.fade-disappear-up.animate {
  transform: translateY(-10%);
  opacity: 0;
}

/* ... other styles ... */

.footer-container {
  padding: 1rem 0;
  border-top: 1px solid #eaeaea;
  text-align: center;
}

.footer-content {
  margin: 1rem 0;
  font-size: 0.8rem;
  color: slategrey;
}

.patreon-logo {
  width: 100px; /* Smaller for subtlety */
  margin: 1rem 0;
}

@media (max-width: 600px) {
  .app-container {
    padding: 1rem;
  }

  .not-found-container,
  .footer-container, {
 /* Assuming the not-found and footer containers need to be full-width on mobile */
    padding: 1rem;
    text-align: center; /* Center text if it's not already centered */
  }

  .not-found-container h1 {
    /* Reduce the size of the heading in the not-found-container for small screens */
    font-size: 3rem; /* Smaller value from initially 5rem */
  }

  .footer-content,
  .patreon-logo {
    /* Stack footer content and smaller Patreon logo for mobile */
    display: block; /* if necessary */
    width: 100%; /* Full width for smaller screen */
    margin-top: 0.5rem; /* Give some top margin if needed */
    margin-bottom: 0.5rem;
  }

  .patreon-logo {
    max-width: 80px; /* Smaller logo for mobile */
    height: auto; /* Maintain aspect ratio of image */
  }

  .App-link {
    /* Possibly increase the tap target size for links */
    padding: 0.75rem; /* Larger padding for easier interaction on touch devices */
  }

  .App-header {
    /* Adjust header padding on mobile */
    padding: 1rem;
  }

  .App-logo {
    /* Adjust logo size on mobile, if necessary */
    height: 50px; /* Smaller logo for mobile */
  }
}
